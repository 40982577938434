
.nav-link.active {
    color: #404040 !important;
    background-color: white !important;
    flex: 1;
    font-weight: bold;
    text-align: center;
  }

.nav-link {
    background-color: white !important;
    color: #404040 !important;
    flex: 1;
    text-align: center;
}

.nav-tabs .nav-link.active {
    border-bottom-width: 5px;
    border-color: #dd1d21;
    color: #dd1d21 !important;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
}

.nav-tabs .nav-link {
    border-bottom-width: 5px;
    border-color: #a0a0a0;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
}

.nav-tabs .nav-link:hover {
    border-bottom-width: 5px;
    border-color: #404040;
    font-weight: bold;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
}

.nav-tabs {
    background-color: white !important;   

}

.tab-content {
    height: calc(100% - 44px);
    display: flex;
    flex-flow: column;
}

.box-shadow {
    box-shadow: 0 3px 3px -3px lightslategrey;
}

.box-shadow-full {
    box-shadow: 0 0 10px 2px lightgray;
}

.separator {
    height: 1px;
    width: 100%;
    background-color: lightgray;
}

.tab-pane {
    height: 100%;
    display: flex;
    flex-flow: column;
}

.inline-roi-link:hover {
    background-color:#d9d9d9 !important;
}

/*map related*/
.ol-control{
    bottom: 0px;
    background-color: transparent !important;
}

/*input -> remove x, IE*/
input::-ms-clear {
    display: none;
}

/*date-picker*/
.react-datepicker-ignore-onclickoutside{
    height: 35px;
}

.react-datepicker-wrapper input{
    padding-left: 5px;
    height: 35px;
    font-size: .875rem !important;
    color: #404040;
}
.react-datepicker-wrapper {
    height: 35px;
}

.icon-datepicker{
    height: 35px !important;
}

.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container,
.customDatePickerWidth > div > div.react-datepicker__input-container input,
.react-datepicker-ignore-onclickoutside,
.react-datepicker__input-container input{
   width: 120px;
   height: 35px;
}

/*hiding inner arrows from number input*/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}

/*modal */
.map-modal-dialog {
    max-width: none !important;
    width: 70%;
}

.roi-map-modal-dialog {
    max-width: none !important;
    width: 900px;
}

.intersection-button
    { 
        background-color: transparent !important; 
        border-color: #007bff; 
        margin-right: 10px;
        color: #007bff !important;
    }

.intersection-button:hover
    { 
        background-color: #007bff !important; 
        border-color: transparent !important; 
        color: white !important;
    }
.param-selection table{
    border-collapse: separate;
  border-spacing: 15px 0px;
}

.param-selection td{
    padding: 0px 0;
}

.param-selection td:nth-child(2){
    font-weight: 600;
}

  .bottom-border-gray {
    border: 1px solid lightgrey;
  }

.shell-logo{
    height: 45px;
    margin-right: 10px;
    background-color: white;
    padding: 2px;
}

.card{
    border-radius: 0px !important;
}

.form-group, .shell-input, .input-group-text, .btn{
    border-radius: 0px !important;
    font-size: .875rem !important;
    height: 35px !important;
    color: #404040;
}

.shell-input{
    padding-left: 5px;
}

.btn:focus{
    color: #404040;
}

.shell-dropdown, .shell-dropdown:hover, .shell-dropdown:focus .shell-dropdown:disabled, .dropdown, .dropdown:disabled{
    background-color: transparent !important;
    border-color: #a0a0a0 !important;
    color: #404040 !important;
    font-weight: normal !important;
}

.dropdown-item{
    font-size: .875rem !important;
}

.dropdown-menu{
    border-radius: 0px;
}

.shell-navbar{
    background-color: #fbce07;
    height: 56px;
}

.shell-block{
    background-color: #f2f2f2;
    border-bottom-width: 3px;
    border-bottom-color: #fbce07;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
}

.bottom-light-border td{
    border: 1px solid #ccc!important;
    border-color:  lightgrey !important;
    padding-left: 5px;
    padding-right: 5px;
}

.box-border {
    border: 1px solid #ccc!important;  
}

.status-table {
    border-collapse: collapse;
    table-layout: fixed ;
    font-size: 12px;
  }
  .status-table td, .status-table th {
    border: 1px solid #ccc;
    text-align: center;
  }
  .status-table tr:first-child td, .status-table tr:first-child th {
    border-top: 0;
  }
  .status-table tr td:first-child, .status-table tr th:first-child {
    border-left: 0;
  }
  .status-table tr:last-child td, .status-table tr:last-child th {
    border-bottom: 0;
  }
  .status-table tr td:last-child, .status-table tr th:last-child {
    border-right: 0;
  }

.btn{
    font-weight: bold;
}

.shell-yellow-btn {
    background-color: #404040;
    border-color: #404040;
    color: #fbce07 !important;
}

.shell-yellow-btn:hover {
    background-color: #fbce07;
    border-color: #404040;
    color: #404040 !important;
}

.shell-yellow-btn:hover svg{
    color: #404040 !important;
}

.shell-yellow-btn-icon {
    color: #fbce07 !important;
}

.shell-gray-btn-icon {
    color: white !important;
}

.shell-gray-btn, .shell-gray-btn:focus {
    background-color: #404040;
    color: white;
    border-color: #404040;
}

.shell-gray-btn:hover svg{
    color: #404040 !important;
}

.shell-gray-btn:disabled, .shell-gray-btn:disabled:hover {
    background-color: #404040;
    color: white;
    border-color: #404040;
}

.shell-gray-btn:hover {
    background-color: white;
    color: #404040;
    border-color: #404040
}

.shell-secondary-btn, .shell-secondary-btn:hover {
    background-color: transparent !important;
    color: #404040;
    border-color: #404040;
}

.shell-secondary-btn:disabled{
    background-color: transparent;
    color: lightgray;
    border-color: lightgray;
}

.shell-full-yellow-btn{
    background-color: #fbce07;
    color: #404040;
}

.shell-red-btn, .shell-red-btn:hover, .shell-red-btn:focus{
    background-color: #dd1d21;
    color: white;
    border-color: transparent;
}

.shell-red-white-btn,  .shell-red-white-btn:focus{
    background-color: #dd1d21;
    color: white;
    border-color: transparent;
}
.shell-red-white-btn:hover{
    background-color: white;
    color:  #dd1d21;
    border-color: #dd1d21;
}

.shell-red-white-btn:hover svg{
    color: #dd1d21 !important;
}

.brand {
    font-weight: 700;
    font-size: large;
    font-size: 1.625rem;
    color:#404040;
}

.simple-link{
    text-decoration: underline !important;
    color: #007bff !important;
    cursor: pointer;
}

.modal-content{
    border-radius: 0px !important;
    color: #404040 !important;
}

.disabled-link{
    color: lightgray !important;
}

.react-datepicker__day--outside-month {
    visibility: hidden;
}

.data_selector_table th {
    text-align: right;
    font-weight: normal;
    padding: 0.2em 0.2em;
}

.data_selector_table td {
    padding: 0.2em 0.6em 0.2em 0.3em;
}

.react-datepicker-wrapper {
    height: 25px;
}

.react-datepicker__input-container input {
    height: 25px;
}

